.container {
  position: relative;
  width: 100%;
  max-width: 2000px; /* Maximum width for larger screens like laptops */
  margin: 0 auto;
  overflow: hidden;
}

img {
  width: 116%;
  height: 600px;
  margin-top: 64px;
  margin-left: -281px;
  object-fit: cover;
}

.left-btn,
.right-btn {
  position: absolute;
  top: 40%;
}

button {
  background-color: rgb(34, 55, 60);
  color: white;
  font-size: 25px;
  border: none;
  cursor: pointer;
}

.left-btn {
  left: 5px;
}
.right-btn {
  right: 5px;
}

@media (min-width: 516px) and (max-width: 990px) {
  .container {
    max-width: 700px;
  }
  img {
    width: 125%;
    height: 400px;
    margin-top: 49px;
    margin-left: -92px;
  }
  .left-btn,
  .right-btn {
    font-size: 22px;
    padding: 8px;
  }
}
@media (min-width: 300px) and (max-width: 515px) {
  .container {
    max-width: 100%;
    padding: 0 15px;
  }

  img {
    width: 107%;
    height: 300px;
    margin-top: 56px;
    margin-left: -15px;
  }

  .left-btn,
  .right-btn {
    font-size: 18px;
    padding: 6px;
  }
}
